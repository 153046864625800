import axios from "axios";
import { isClassDeclaration } from "typescript";
import { IProjectOfStudent } from "../interface/Interface";
import { admintoken } from "./Method";

// const uri = "http://10.185.15.24:4000";
const uri = "https://api.dahandin.com"; // 백엔드 - 본 작업 + 프론트작업할때
// const uri = "http://172.30.1.65:4000"; // 재환 대표님 로컬
// version5 test api
// const uri = "https://testapi.dahandin.com";
// const uri = "http://10.185.15.22:4000";

const token: any = localStorage.getItem("token");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

export const getData = async (
  _collection: string,
  _find?: object | string,
  _sort?: object | string,
  _start?: number,
  _end?: number,
): Promise<object | boolean> => {
  try {
    const collection = _collection;
    let find = _find ? _find : {};
    find = JSON.stringify(find);
    let sort = _sort ? _sort : {};
    sort = JSON.stringify(sort);
    const start = _start ? _start : 0;
    const end = _end ? _end : 0;
    const { data }: any = await axios.get(
      `${uri}/get/data?collection=${collection}&find=${find}&sort=${sort}&start=${start}&end=${end}`,
    );
    return data;
  } catch (error) {
    return false;
  }
};

export const getByToken = async (
  _collection: string,
  _find?: object,
  _sort?: object,
  _start?: number,
  _end?: number,
): Promise<object | boolean> => {
  try {
    const collection = _collection;
    const token = localStorage.getItem("token");
    // console.log(token);

    let find: any = _find ? _find : {};

    find = JSON.stringify(find);

    let sort: any = _sort ? _sort : {};
    sort = JSON.stringify(sort);
    const start = _start ? _start : 0;
    const end = _end ? _end : 0;
    const { data }: any = await axios.get(
      `${uri}/get/bytoken?collection=${collection}&token=${token}&find=${find}&sort=${sort}&start=${start}&end=${end}`,
    );
    return data;
  } catch (error) {
    return false;
  }
};

export const getNotice = async (): Promise<object | boolean> => {
  try {
    const { data }: any = await axios.get(`https://api.dahandin.com/get/notice`);
    return data;
  } catch (error) {
    return false;
  }
};

export const getSearch = async (
  _collection: string,
  _find: object,
  _sort?: object,
  _start?: number,
  _end?: number,
): Promise<object | boolean> => {
  try {
    const collection = _collection;
    let find = { $text: { $search: `${_find}` } };
    const sort = _sort ? _sort : {};
    const start = _start ? _start : 0;
    const end = _end ? _end : 0;
    const { data }: any = await axios.get(
      `${uri}/get/data?collection=${collection}&find=${JSON.stringify(find)}&sort=${JSON.stringify(
        sort,
      )}&start=${start}&end=${end}`,
    );
    return data;
  } catch (error) {
    return false;
  }
};

export const postInsert = async (_body: object): Promise<boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/post/insert`, { _body });
    return data;
  } catch (error) {
    return false;
  }
};

export const postInsertVerify = async (_body: object): Promise<boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/post/insertverify`, _body);
    return data;
  } catch (error) {
    return false;
  }
};

// export const postUploadImage = async (_file: object, _size: number): Promise<string | boolean> => {
//   try {
//     const token = localStorage.getItem("token");
//     const { data }: any = await axios.post(`${uri}/post/uploadimage?size=${_size}`, _file, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

// export const postUploadFile = async (_file: object): Promise<string | boolean> => {
//   try {
//     const token = localStorage.getItem("token");
//     const { data }: any = await axios.post(`${uri}/post/uploadfile`, _file, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

export const postUpdate = async (_body: object): Promise<boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/update/item`, _body);
    return data;
  } catch (error) {
    return false;
  }
};

export const postDelete = async (_body: object): Promise<boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/delete/item`, _body);
    return data;
  } catch (error) {
    return false;
  }
};

export const postDeleteMany = async (_body: object): Promise<boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/delete/many`, _body);
    return data;
  } catch (error) {
    return false;
  }
};

// export const postPasswordChange = async (_body: object): Promise<boolean> => {
//   try {
//     const { data }: any = await axios.post(`${uri}/auth/passwordChange`, _body);
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

// export const getSendEmailToken = async (_email: string): Promise<boolean> => {
//   try {
//     const { data }: any = await axios.get(`${uri}/auth/sendemailtoken?email=${_email}`);
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

export const getExistId = async (id: string): Promise<boolean | undefined> => {
  try {
    const { data }: any = await axios.get(`${uri}/auth/existid?=${id}`);
    return data;
  } catch (error) {
    return false;
  }
};

export const getExistEmail = async (_email: string): Promise<boolean | undefined> => {
  try {
    const { data }: any = await axios.get(`${uri}/auth/existEmail?email=${_email}`);
    return data;
  } catch (error) {
    return false;
  }
};

// export const getFindId = async (email: string): Promise<boolean> => {
//   const { data } = await axios.get(`${uri}/auth/findid?email=${email}`);
//   return data;
// };

// export const postPasswordChange = async (password: string): Promise<boolean> => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const { data } = await axios.post(`${uri}/auth/passwordchange`, {
//       password: password,
//       token: token,
//     });
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

export const postUserEdit = async (body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const { data } = await axios.post(`${uri}/auth/useredit`, {
      ...body,
      token: token,
    });
    return data;
  } catch (error) {
    return false;
  }
};

export const loginKakao = async (_data: any) => {
  const { data } = await axios.post(`${uri}/auth/loginkakao`, {
    ..._data,
  });
  return data;
};

// export const postNotification = async (body: any) => {
//   try {
//     // console.log(body);
//     const result = await axios.post(`${uri}/post/notificationexpo`, {
//       ...body,
//       sound: "default",
//     });
//     return result;
//   } catch (error) {
//     return false;
//   }
// };

// 회원가입
export const postSignup = async (_body: object): Promise<boolean | string> => {
  try {
    const { data }: any = await axios.post(`${uri}/auth/signup`, _body);
    return data;
  } catch (error) {
    return false;
  }
};

// 로그인
export const getSignin = async (_body: object): Promise<string | boolean> => {
  try {
    const { data }: any = await axios.post(`${uri}/auth/signin`, _body);

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 회원가입 중복확인
export const getUserVerify = async (type: string, value: string): Promise<boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/users/exist?${type}=${value}`);

    return data;
  } catch (error) {
    return false;
  }
};

// 아이디 찾기(메일전송)
export const getFindId = async (email: string): Promise<boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/users/username?email=${email}`);

    return data;
  } catch (error) {
    return false;
  }
};

// 비밀번호 찾기(메일전송)
export const getSendEmailToken = async (_email: string): Promise<boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/users/sendEmailToken?email=${_email}`);
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 비밀번호 변경
export const postPasswordChange = async (password: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(
      `${uri}/users/password`,
      {
        password: password,
      },
      { headers },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 토큰으로 유저정보 조회
export const getUserInfo = async (): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");

    const { data }: any = await axios.get(`${uri}/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 두클래스 연동 코드 생성
export const postCreateLinkCode = async (_body: any): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/users/createlinkcode`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 두클래스 연동 코드로 토큰발급
export const getLinkToken = async (_linkcode: string): Promise<object | boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/openapi/tokenissuance`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_linkcode}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 두클래스 oauth v2
export const getTokenAsOAuthToken = async (normalToken: string): Promise<object | boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/openapi/oauthtokenredirect`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${normalToken}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 유저정보 변경
export const putModifyUserInfo = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/users/all`, { ..._body }, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 유저정보 변경 ai 동의서
export const putModifyUserAgreement = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/users/agreementai`, { ..._body }, { headers });

    return data;
  } catch (error) {
    return false;
  }
};
// 유저정보 변경 policy 동의서
export const putModifyUserAgreementPolicy = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/users/agreementpolicy`, { ..._body }, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 담당 학급조회
export const getClassesInfo = async (): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/classes/list`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급 단일 조회
export const getClassInfo = async (_id: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/classes?id=${_id}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급 정렬
export const putClassesSorting = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.put(`${uri}/classes/sort`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학급 목표 수정
export const putModifyClassTargetAction = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.put(`${uri}/classes/target`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학급 생성
export const postInsertClass = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/classes`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학급명 수정
export const putModifyClasses = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.put(`${uri}/classes`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학급 내 학생 모두 조회
export const getStudents = async (classId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/students/list?classId=${classId}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급 내 쿠키 조회
export const getCookiesInClass = async (classId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/classes/cookies?id=${classId}`, { headers });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 과제 목록 조회
export const getProjects = async (
  classId: string,
  hide: boolean,
  sort: string = "created",
  direction: number = 1,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/projects/list/${hide}?token=${token}&classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
      { headers },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 과제 단일 조회
export const getProject = async (projectId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/projects?id=${projectId}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 이미지 업로드(사이즈 지정)
export const postUploadImage = async (_file: object, _size: number = 500): Promise<string | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const { data }: any = await axios.post(`${uri}/common/uploadimage?size=${_size}&token=${token}`, _file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 파일 업로드
export const postUploadFile = async (_file: object): Promise<string | boolean> => {
  try {
    const token: any = localStorage.getItem("token");

    const { data }: any = await axios.post(`${uri}/common/uploadfile`, _file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 과제 생성
export const postAddProject = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/projects`, _body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 과제별 숙제 목록 조회
export const getProjectOfStudents = async (projectId: string): Promise<IProjectOfStudent[] | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/pos/list/project?token=${token}&projectId=${projectId}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};
// 숨김과제
export const getProjectOfStudentsHidden = async (projectId: string): Promise<IProjectOfStudent[] | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/pos/list/hiddenproject?token=${token}&projectId=${projectId}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 과제 수정
export const putModifyProject = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/projects`, _body, { headers });
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 학생 생성
export const postAddStudent = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/students`, _body, { headers });
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 학생 목록 삭제
// export const deleteStudents = async (list: string[]): Promise<boolean> => {
//   try {
//     const { data } = await axios.delete(`${uri}/not/list`, { headers });

//     return data;
//   } catch (error) {
//     return false;
//   }
// };

// 학생별 숙제 목록 보기
export const getEachStudentProject = async (
  code: string,
  sort: string = "created",
  direction: number = 1,
  anyProject: boolean = false,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/pos/list/student?code=${code}&sortBy=${sort}&sortDirection=${direction}&anyProject=${anyProject}`,
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 학생 단일 조회
export const getEachStudentInfo = async (studentId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/students?&id=${studentId}`, { headers });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 숙제 단일 조회
export const getEachProjectInfo = async (posId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/pos?id=${posId}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학생 정보 변경
export const putModifyStudentInfo = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/students`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 과제 수정(숨김처리)
export const putHideProject = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/projects/hiding`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 숙제 확인(일반 과제)
export const putConfirmProject = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/pos/confirmation`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 숙제 반려
export const putRejectProject = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/pos/rejection`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 생성
export const postNotice = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/not`, _body, { headers });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 알림장 조회
// export const getNoticeBook = async (
//   classId: string,
//   sort: string = "created",
//   direction: number = 1,
// ): Promise<boolean | object> => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const { data }: any = await axios.get(
//       `${uri}/not/classes?classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     );
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

// new api version
// 학급별 알림장 조회
export const getNoticeBook = async (
  classId: string,
  sort: string = "created",
  direction: number = 1,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const { data }: any = await axios.get(
      `${uri}/api/classnot?classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 숨긴 알림장 조회
export const getHiddenNotice = async (
  classId: string,
  sort: string = "created",
  direction: number = 1,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const { data }: any = await axios.get(
      `${uri}/api/classnothide?classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 학생 쿠키 개수 변경2 (쿠키 사용/회수)
export const putCookies = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/students/cookies2`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 단일 조회
export const getEachNoticeBook = async (noticeId: string, temp?: boolean): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/not?id=${noticeId}&temp=${temp ? true : false}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장별 학생 알림장 목록
export const getNos = async (noticeId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/nos/list/not?noticeId=${noticeId}`, {
      headers,
    });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 수정
export const putModifyNoticeBook = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/not`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 삭제
export const deleteNoticeBookList = async (list: string[]): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.delete(`${uri}/not/list`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장, 체크리스트, 학생 목록, 과제, 학급 삭제
export const deletelist = async (type: string, list: string[]): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");

    const { data } = await axios.delete(`${uri}/${type}/list`, {
      data: {
        idList: list,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

export const deletelist2 = async (list: string[], folderIdList: string[], classId: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");

    const { data } = await axios.delete(`${uri}/api/checklist`, {
      data: {
        idList: list,
        folderIdList: folderIdList,
        classId: classId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 생성
export const postChecklist = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/api/checklist`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 체크리스트 조회
// export const getCheckLists = async (
//   classId: string,
//   sort: string = "created",
//   direction: number = 1,
// ): Promise<boolean | object> => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     const { data }: any = await axios.get(
//       `${uri}/checklist/classes?classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
//       {
//         headers,
//       },
//     );

//     // console.log(data);
//     return data;
//   } catch (error) {
//     return false;
//   }
// };

// 체크리스트페이지 조회
export const getCheckLists = async (
  classId: string,
  sort: string = "created",
  direction: number = 1,
  hide: boolean = false,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/api/checklist?classId=${classId}&sortBy=${sort}&sortDirection=${direction}&hide=${hide}`,
      {
        headers,
      },
    );

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

export const getChecklistsHide = async (
  classId: string,
  sort: string = "created",
  direction: number = 1,
): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/api/checklistgethide?classId=${classId}&sortBy=${sort}&sortDirection=${direction}`,
      {
        headers,
      },
    );

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 단일조회
export const getChecklist = async (checklistId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/checklist?id=${checklistId}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트별 학생 체크리스트 목록 조회
export const getCos = async (checklistId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/cos/list?checklistId=${checklistId}`, {
      headers,
    });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 학생 체크리스트 상세 기록 수정
export const putModifyCos = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/cos`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학생 체크리스트 체크
export const putCheckCos = async (cosId: string, state: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/cos/check?id=${cosId}&state=${state}`, {}, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학생 체크리스트 체크취소
export const putCancelCheckCos = async (cosId: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/cos/uncheck?id=${cosId}`, {}, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 삭제
export const deleteChecklist = async (noticeId: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.delete(`${uri}/not?id=${noticeId}`, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 초기화
// export const putInitializeChecklist = async (checklistId: string): Promise<boolean> => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     const { data } = await axios.put(
//       `${uri}/checklist/initialization?id=${checklistId}`,
//       {},
//       { headers },
//     );

//     return data;
//   } catch (error) {
//     return false;
//   }
// };
export const putInitializeChecklist = async (checklistId: string, classId: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/checklistinit?id=${checklistId}&classId=${classId}`, {}, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 수정
// export const putModifyChecklist = async (_body: any): Promise<boolean> => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     const { data } = await axios.put(`${uri}/checklist`, _body, { headers });

//     return data;
//   } catch (error) {
//     return false;
//   }
// };

//new version
export const putModifyChecklist2 = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/checklist`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 학생별 학생 체크리스트 목록 조회
export const getCheckistForStudentPage = async (code: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/cos/list/student?code=${code}`, {
      headers,
    });

    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 푸시알림
// export const postNotification = async (body: any) => {
//   try {
//     if (body.to === undefined || body.to === null || body.to === "") {
//       return false;
//     }

//     const result = await axios.post(
//       `${uri}/common/notificationexpo`,
//       {
//         ...body,
//         sound: "default",
//       },
//       { headers },
//     );
//     return result;
//   } catch (error) {
//     return false;
//   }
// };

// export const postNotificationMulti = async (_body: any) => {
//   try {
//     const token: any = localStorage.getItem("token");
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     const body: any = [];
//     for (let i in _body) {
//       if (_body[i].to) {
//         _body[i].sound = "default";
//         body.push(_body[i]);
//       }
//     }

//     const result = await axios.post(`${uri}/common/notificationexpo`, body, { headers });
//     return result;
//   } catch (error) {
//     return false;
//   }
// };

// 푸시알림 업데이트버전 single push
export const postNotification = async (body: any) => {
  try {
    if (body.to === undefined || body.to === null || body.to === "") {
      return false;
    }

    const result = await axios.post(
      `${uri}/common/fcmnotificationsingle`,
      {
        token: body.to,
        notification: {
          title: body.title,
          body: body.body,
        },
        data: {
          ...body.data,
        },
      },
      { headers },
    );

    // console.log("RESULT", result);
    return result;
  } catch (error) {
    return false;
  }
};

// 푸시알림 업데이트버전 multi post -> 데이터 동일
export const postNotificationMulti = async (_message: any) => {
  try {
    const message: any = {
      tokens: [
        "duYDl-xKQ8aF6tMzn_dUM9:APA91bFAZqG8w5JqvhFLckO9Yh8j2HC-LR2I4vjwrzR0c-q6YdkEVVHnMaG7npNeGNC_fjrXxF2P663AFbzU4hAJf3bCAQYpibLAPflKjMKP-fvHXiO2c8Zs_LieCBQ9fPdFiWE3fb4m",
      ],
      notification: {
        body: "This is an FCM notification that displays an image!",
        title: "FCM Notification",
      },
      data: {
        type: "어쩌고",
      },
      apns: {
        payload: {
          aps: {},
        },
      },
      android: {},
    };

    const result = await axios.post(
      `${uri}/common/fcmnotificationmulti`,
      { ...message },
      {
        headers,
      },
    );

    // console.log(result);
    return result;
  } catch (error) {
    return false;
  }
};

// 푸시 메세지 전송 multi post -> 데이터 다르게
export const postNotificationAll = async (pushMessages: any) => {
  try {
    const message: any = pushMessages;

    // messages data example
    // filteredStudentsData.map((el: any, i: number) => {
    //   if (el.deviceToken2) {
    //     pushMessages.push({
    //       token: el.deviceToken2,
    //       notification: {
    //         title: "알림",
    //         body: "숙제를 확인해 주세요.",
    //       },
    //       data: {
    //         type: "project",
    //         code: el.code,
    //       },
    //     });
    //   }
    // });
    const result = await axios.post(
      `${uri}/common/fcmnotificationall`,
      {
        message: [...message],
      },
      {
        headers,
      },
    );

    return result;
  } catch (error) {
    return false;
  }
};

// 뱃지 등록/수정
export const putBadges = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/bt/badges`, body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학급 뱃지 조회
export const getBadges = async (classId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/bt?classId=${classId}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 학생뱃지 목록 조회
export const getBs = async (classId: string): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/bs/list?classId=${classId}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급별 간편 학생 뱃지 수정
export const putBs = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/bs/easy-update`, body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 학생별 간편 학생 뱃지 수정
export const putEachBs = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/bs/easy-update/student`, body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 뱃지 삭제
export const putDeleteBadge = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/bt/badges/removal`, body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 관리자 알림
export const getAdminNotice = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/notice`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 리포트데이터
export const getReportProjects = async (classId: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/classreport?classId=${classId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};
export const getClassChecklists = async (classId: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/classchecklist?classId=${classId}`, {
      headers,
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 개별 학생 리포트데이터
export const getStudentReportProjects = async (classId: string, studentId: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/studentreport?classId=${classId}&studentId=${studentId}`, {
      headers,
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 개별 학생 체크리스트 데이터(학생리포트 체크리스트)
export const getStudentChecklistReport = async (classId: string, studentId: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/api/studentchecklistreport?classId=${classId}&studentId=${studentId}`,
      {
        headers,
      },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 분류기능
export const postCategory = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const result = await axios.post(`${uri}/api/projecttags`, body, { headers });
    return result;
  } catch (error) {
    return false;
  }
};

export const getCategorys = async (classId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/projecttags?classId=${classId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 누가기록
export const postStackRecord = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.post(`${uri}/api/record`, body, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

export const getStackRecords = async (classId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/record?classId=${classId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

export const getStudentStackRecords = async (classId: string, studentId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/studentrecord?classId=${classId}&studentId=${studentId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

//누가기록 삭제
export const deleteStackRecords = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.delete(`${uri}/api/record`, {
      data: {
        ..._body,
      },
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

//누가기록 삭제(쿠키쓰기)
export const deleteStackRecords2 = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.delete(`${uri}/api/record2`, {
      data: {
        ..._body,
      },
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

export const modifyStackRecord = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/record`, { ..._body }, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 누가기록 분류 추가
export const postRecordTags = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const result = await axios.post(`${uri}/api/recordtags`, body, { headers });
    return result;
  } catch (error) {
    return false;
  }
};

export const getRecordTags = async (classId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/recordtags?classId=${classId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 폴더 생성
export const postChecklistFolder = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const result = await axios.post(`${uri}/api/checklistfolder`, body, { headers });
    return result;
  } catch (error) {
    return false;
  }
};

export const getChecklistFolders = async (classId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/checklistfolder?classId=${classId}`, { headers });
    return data;
  } catch (error) {
    return false;
  }
};

// 해당 폴더 내 체크리스트 조회
export const getChecklistOnFolder = async (classId: string, folderId: string, hide: boolean = false) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `${uri}/api/checklistonfolder?classId=${classId}&folderId=${folderId}&hide=${hide}`,
      {
        headers,
      },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 폴더 조회
export const getFoldersOfChecklist = async (classId: string) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`${uri}/api/checklistfolderlist?classId=${classId}`, {
      headers,
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 폴더명 변경
export const putModifyFoldersTitle = async (_body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // const { data } = await axios.put(`${uri}/api/checklistfolder`, {
    //   data: {
    //     ..._body,
    //   },
    //   headers,
    // });
    const { data } = await axios.put(`${uri}/api/checklistfolder`, _body, {
      headers,
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 태그삭제
export const deleteTag = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.delete(`${uri}/api/projecttags`, {
      data: {
        ..._body,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 누가기록 태그 삭제
export const deleteRecordTag = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.delete(`${uri}/api/recordtag`, {
      data: {
        ..._body,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return false;
  }
};

// 체크리스트 숨김
export const putHideChecklist = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/checklisthide`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 우리반 쿠키 쓰기
export const putClassCookies = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/usedcookie`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 우리반 리포트 -> 체크리스트 조회
export const getClassReportChecklist = async (
  classId: string,
  folderId: string = "",
  notFolder: boolean = false,
): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(
      `${uri}/api/classchecklist?classId=${classId}&folderId=${folderId}&notFolder=${notFolder}`,
      {
        headers,
      },
    );
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 임시저장
export const postTempSave = async (body: any) => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const result = await axios.post(`${uri}/api/tempsavenot`, body, { headers });
    return result;
  } catch (error) {
    return false;
  }
};

// 임시저장 수정
export const putTempNoticeEdit = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/edittempsavenot`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 임시저장 삭제
export const deleteTempNotice = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.delete(`${uri}/api/deletetempsavenot`, {
      data: {
        ..._body,
      },
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 알림장 숨김
export const hideNotice = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/api/hidenot`, _body, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// version5 전체 학급 당 학생 불러오기
export const getAllClasssPerStudent = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/openapi/classlistwithstudents`, {
      headers,
    });
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// 과제 일괄 생성
export const postAddProject2 = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/openapi/createprojectstudents`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 학급 숨김
export const putHideClass = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/classes/classhide`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// ai feedback 생성
export const postAIFeedback = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/llm/studentfeedback`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// ai prompt 생성
export const postGenerateAI = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/llm/ask`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// version5 전체 학급 당 학생 불러오기
export const getIsValidGenerateAI = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/llm/isgenerating`, {
      headers,
    });
    // console.log(data);
    return data;
  } catch (error) {
    return false;
  }
};

// ai prompt multi 생성
export const postGenerateAIMulti = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/llm/askmulti`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 다했니 데이터에 저장
export const postSaveAiFeedback = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/llm/airecords`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// ai 피드백 불러오기
export const getSavedAiFeedback = async (classId: string): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/llm/airecords?classId=${classId}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// ai 피드백 수정
export const putMoodifySavedAiFeedback = async (_body: any): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(`${uri}/llm/airecords`, { ..._body }, { headers });

    return data;
  } catch (error) {
    return false;
  }
};

// 저요 post create Meyo
export const postCreateMeyo = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/meyo/create`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 저요 get meyo list
export const getMeyoList = async (sort: number): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/meyo/list?sort=${sort}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 저요 get meyo list 선택 후 디테일들어가기
export const getMeyoDetail = async (classId: string, _id: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/meyo/detail/${classId}/${_id}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 저요 update
export const postUpdateMeyo = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/meyo/update`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 저요 delete 삭제
export const postDeleteMeyo = async (_body: object): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(`${uri}/meyo/delete`, _body, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 계정별 파일측정
export const getCalculateClassFileSize = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/filesize/classfilesize`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 카카오 회원탈퇴
export const postCancelMembershipKakao = async (): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(
      `${uri}/auth/kakaounlink`,
      {},
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 일반회원탈퇴
export const postCancelMembership = async (): Promise<boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.post(
      `${uri}/auth/userdelete`,
      {},
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 이메일전송
export const postEmail = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/sendemail`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 이메일전송 어드민
export const postEmailAdmin = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(`${uri}/admin/sendemail?token=${admintoken}`, {
      ...body,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 시리얼넘버 등록
export const postRegisterSerialCode = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/verifyserial`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 전체용량측정
export const getFileSize = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/classtotalfilesize`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 단체이용권 등록여부 확인
export const getIsRegisteredSerialCode = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/registereduser`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 시리얼넘버 해지
export const postCancelSerialCode = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/serialcodecancel`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// serialCode 등록
export const postAddSerialCode = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/serialcode`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 결제내역 등록
export const postPayment = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/payment`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

//결제내역 id로 조회
export const getPaymentDetailId = async (body: any): Promise<object | boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/api/payment?id=${body.id}&uid=${body.uid}`);

    return data;
  } catch (error) {
    return false;
  }
};

export const putPayment = async (body: any): Promise<boolean | object> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.put(
      `${uri}/api/payment`,
      {
        ...body,
      },
      { headers },
    );
    return data;
  } catch (error) {
    return false;
  }
};

// 결제내역 리스트조회
export const getPayments = async (): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/paymentrecords`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};
// 결제내역 리스트 한 개 조회 (우리쪽디비)
export const getPaymentItem = async (imp_uid: string): Promise<object | boolean> => {
  try {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const { data }: any = await axios.get(`${uri}/api/paymentitem?imp_uid=${imp_uid}`, {
      headers,
    });

    return data;
  } catch (error) {
    return false;
  }
};

// 결제내역 포트원조회
export const getPortonePayment = async (uid: string): Promise<object | boolean> => {
  try {
    const { data }: any = await axios.get(`${uri}/api/iamportpayment?imp_uid=${uid}`);

    return data;
  } catch (error) {
    return false;
  }
};

// 결제취소 iamport
export const postCanceoPaymentIamport = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/iamportcancel`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 결제취소시 모든 시리얼코드 삭제
export const deleteAllSerialCode = async (body: object): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/deleteserialcode`,
      {
        ...body,
      },
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 개인결제 완료시
export const postPaidAfterPrivatePurchase = async (_body: any): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/paidpersonal`,
      { ..._body },
      // {
      //   headers,
      // },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// 개인결제 취소시
export const postPaidAfterPrivatePurchaseCancel = async (): Promise<boolean> => {
  try {
    const { data } = await axios.post(
      `${uri}/api/paidcancelpersonal`,
      {},
      {
        headers,
      },
    );

    return data;
  } catch (error) {
    return false;
  }
};

// google login
export const loginGoogle = async (_data: any) => {
  const { data } = await axios.post(`${uri}/auth/logingoogle`, {
    ..._data,
  });
  return data;
};

// get user from google
export const getUserFromGoogle = async (accessToken: string): Promise<boolean | object> => {
  try {
    const { data }: any = await axios.get(`https://www.googleapis.com/oauth2/v2/userinfo`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (error) {
    return false;
  }
};

import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { IMainContext, IProject, IProjectOfStudent, IStudent } from "../interface/Interface";
import ReactQuill from "react-quill";
import { CalendarDay, DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape } from "react-dates";
import cookie1 from "../images/cookie1.png";
import cookie2 from "../images/cookie2.png";
import cookie3 from "../images/cookie3.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import down_b from "../images/down_b.png";
import close from "../images/close.png";
import back from "../images/arrow-left.png";
import {
  postUploadFile,
  postNotification,
  getProjectOfStudents,
  getProject,
  getStudents,
  putModifyProject,
  putConfirmProject,
  putRejectProject,
  getProjectOfStudentsHidden,
  getCategorys,
  postUploadImage,
  postNotificationAll,
} from "../common/Action";
import PopupAlbum from "../component/PopupAlbum";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import { MainContext } from "../common/Context";
import {
  fileTypeValidate,
  fileViewImage,
  getFileNameAndExtension,
  handleModifyValidCheck,
  sortedImageFileArr,
  time1,
  time2,
  timeFormat11,
} from "../common/Method";
import file_icon from "../images/file_icon.png";
import file_icon2 from "../images/logo-top.png";
import CalendarC from "../component/CalendarC";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import down from "../images/down.png";
import HiddenProjectView from "../component/HiddenProjectView";
import OutsideClickHandler from "react-outside-click-handler";
import { t } from "i18next";

interface IfileInfo {
  fileUrl: string;
  fileType: string;
  fileName: string;
  file: File | null;
}

const Project: React.FC = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  // const confirmRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const [cardData, setCardData] = useState<any>({}); //프로젝트 데이터
  const [studentData, setStudentData] = useState<any[]>([]); //프로젝트 안에 학생 데이터
  const [studentAll, setStudentAll] = useState<IStudent[]>([]); //모든 학생
  const [projectOfStudent, setProjectOfStudent] = useState<any>({}); // 학생이 낸 프로젝트
  const [text, setText] = useState<string>("");
  const [projectNameInput, setProjectNameInput] = useState<string>("");
  const [fileUrls, setFileUrls] = useState<IfileInfo[]>([]);
  const [confirmType, setConfirmType] = useState<string>("");
  const [score, setScore] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [confirmProjectPopup, setConfirmProjectPopup] = useState<boolean>(false);
  const [album, setAlbum] = useState<boolean>(false);
  const [albumUrl, setAlbumUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [studentBodyFalse, setStudentBodyFalse] = useState<boolean>(false);
  const [selectedStudentCode, setSelectedStudentCode] = useState<string>("");
  const [studentCheckWait, setStudentCheckWait] = useState<boolean>(false);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const context = useContext<IMainContext>(MainContext);
  const [badgesArr, setBadgesArr] = useState<any>([]);
  const [popupStudentName, setPopupStudentName] = useState<string>("");
  const [repeatCheck, setRepeatCheck] = useState<boolean>(false);
  const [repeatProjectType, setRepeatProjectType] = useState<string>("");
  const [repeatSelectOpenC, setRepeatSelectOpenC] = useState<boolean>(false);
  const [selectRepeatCount, setSelectRepeatCount] = useState<number>(2);
  const [dates, setDates] = useState<string[]>([]);
  const [targetIdx, setTargetIdx] = useState<number>(-1);
  const [resourceLoading, setResourceLoading] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [albumData, setAlbumData] = useState<any>({});
  const [tags, setTags] = useState<any[]>([]);
  const [hiddenPopup, setHiddenPopup] = useState<boolean>(false);
  const [projectOfStudentData, setProjectOfStudentData] = useState<any>({});
  const [hiddenProjectPopup, setHiddenProjectPopup] = useState<boolean>(false);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [filter] = useState<string[]>([t(`Numerical`), t(`Chronological`)]); // 번호 순, 제출 시각 순
  const [filterSelected, setFilterSelected] = useState<string>(t(`Numerical`)); // 번호 순

  // 과제 일괄 검사 btn
  const [confirmBulk, setConfirmBulk] = useState<boolean>(false);
  const [bulkInfo, setBulkInfo] = useState<any>({
    cookie: 0,
    score: "",
    feedback: "",
  });
  const [studentAllCheck, setStudentAllCheck] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const getFilteredSubmissionTime = (data: any) => {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].submissionTime) {
        data[i].submissionTime = Date.now();
        data[i].tempSubmissionTime = true;
      }
    }
    const filtered = data.sort((a: any, b: any) => {
      return a.submissionTime - b.submissionTime;
    });

    return filtered;
  };

  const handleFilterSelect = async (selectedFilter: string): Promise<void> => {
    setFilterSelected(selectedFilter);
    let filterData: any = [];

    let projectOfStudentResult: any = [];
    if (location.pathname.includes("hidden")) {
      projectOfStudentResult = await getProjectOfStudentsHidden(params.id);

      setHidden(true);
    } else {
      projectOfStudentResult = (await getProjectOfStudents(params.id)) as IProjectOfStudent[]; //projectOfStudent
    }

    if (selectedFilter === t(`Chronological`)) {
      // 제출 시각 순
      filterData = getFilteredSubmissionTime(projectOfStudentResult);
    }

    if (selectedFilter === t(`Numerical`)) {
      // 번호 순
      filterData = projectOfStudentResult.sort((a: any, b: any) => a.studentInfo[0].num - b.studentInfo[0].num);
    }

    setStudentData(filterData);
    setFilterPopup(false);
  };

  const init = async (): Promise<void> => {
    let projectOfStudentResult: any = [];
    if (location.pathname.includes("hidden")) {
      projectOfStudentResult = await getProjectOfStudentsHidden(params.id);

      setHidden(true);
    } else {
      projectOfStudentResult = (await getProjectOfStudents(params.id)) as IProjectOfStudent[]; //projectOfStudent
    }

    if (!projectOfStudentResult) return;
    let tempTags = [];
    const tagsResult: any = await getCategorys(params.classId);
    if (tagsResult.length !== 0) tempTags = tagsResult[0]?.tags;

    const projectDataResult: any = await getProject(params.id); //project data

    // tag 기능
    if (projectDataResult?.tags?.length !== 0) {
      for (let i = 0; i < projectDataResult?.tags?.length; i++) {
        for (let k = 0; k < tempTags?.length; k++) {
          if (projectDataResult?.tags[i] === k) {
            tempTags[k].tagSelected = true;
          }
        }
      }
    }

    setTags(tempTags);

    let studentAllResult: any = await getStudents(params.classId); // all students data
    if (projectDataResult?.repeat) {
      for (let i in projectOfStudentResult as IProjectOfStudent[]) {
        let complete = 0;
        let redCount = 0;
        let openCompleteCount = 0;
        for (let k in projectOfStudentResult[i].repeatData) {
          if (projectOfStudentResult[i].repeatData[k].state === "complete") {
            complete += 1;
          }
          if (projectOfStudentResult[i].repeatData[k].state === "check") {
            redCount += 1;
          }
          if (
            projectDataResult.repeatType === "date" &&
            projectOfStudentResult[i].repeatData[k].open &&
            projectOfStudentResult[i].repeatData[k].state === "complete"
          ) {
            openCompleteCount += 1;
          }
        }
        const openCount = projectOfStudentResult[i].repeatData.filter((el: any, i: number) => el.open).length;

        if (openCount === openCompleteCount) projectOfStudentResult[i].state = "complete";

        projectOfStudentResult[i].completeCount = complete;
      }
    }

    for (let i in studentAllResult) {
      for (let k in projectOfStudentResult) {
        if (
          studentAllResult[i].code === projectOfStudentResult[k].code &&
          (projectOfStudentResult[k].checked || !projectOfStudentResult[k].hide)
        ) {
          studentAllResult[i].checked = true;
        }
      }
    }

    let tempFiles: IfileInfo[] = [];
    for (let i = 0; i < projectDataResult.file?.length; i++) {
      tempFiles.push({
        fileUrl: projectDataResult.file[i],
        fileType: projectDataResult.fileTypes ? projectDataResult.fileTypes[i] : "image",
        fileName: projectDataResult.fileNames ? projectDataResult.fileNames[i] : "",
        file: null,
      });
    }

    const sortedFiles = sortedImageFileArr(tempFiles);

    let description: any = projectDataResult?.description;

    // http://www.douclass.com
    const replaceDesc = description.replace(
      /http:\/\/www.douclass.com/gi,
      "http://download.dahandout.com/open/download/http://www.douclass.com",
    );

    const replaceDesc2 = replaceDesc.replace(
      /http:\/\/stage.douclass.com/gi,
      "http://download.dahandout.com/open/download/http://stage.douclass.com",
    );

    setText(replaceDesc2);

    if (projectDataResult?.repeat && projectDataResult?.repeatType === "date") {
      setDates(projectDataResult.repeatDate);
    }
    setRepeatCheck(projectDataResult?.repeat);
    setRepeatProjectType(
      (projectDataResult?.repeat && projectDataResult?.repeatType) === "count"
        ? t(`By Number of times`) // "횟수형"
        : (projectDataResult?.repeat && projectDataResult?.repeatType) === "date"
        ? t(`By Date Selection`) // "날짜형"
        : "",
    );

    console.log(projectOfStudentResult);
    setCardData(projectDataResult);
    if (filterSelected === t(`Numerical`)) {
      // 번호 순
      setStudentData(projectOfStudentResult.sort((a: any, b: any) => a.studentInfo[0].num - b.studentInfo[0].num));
    } else {
      const getFiltered = getFilteredSubmissionTime(projectOfStudentResult);
      setStudentData(getFiltered);
    }

    setSelectRepeatCount(
      projectDataResult?.repeat &&
        (projectDataResult?.repeatType === "count"
          ? projectDataResult.repeatCount
          : projectDataResult.repeatDate.length),
    );
    setFileUrls(sortedFiles as any);
    setProjectNameInput(projectDataResult?.title);

    setStudentAll(studentAllResult);
  };

  const handleFile = (e: any) => {
    const filesArr = Object.keys(e.target.files);
    let tempFiles: IfileInfo[] = [...fileUrls];

    if (filesArr.length !== 0) {
      for (let i = 0; i < filesArr.length; i++) {
        if (fileTypeValidate(e.target.files[filesArr[i]])) {
          tempFiles.push({
            file: e.target.files[filesArr[i]],
            fileUrl: fileViewImage(e.target.files[filesArr[i]]),
            fileType: getFileNameAndExtension(e.target.files[filesArr[i]]).fileType,
            fileName: e.target.files[filesArr[i]].name,
          });
        } else {
          // return alert("첨부할 수 없는 파일이 있습니다.");
          return alert(t(`There are files that can't be attached`));
        }
      }
    }

    const sortedFiles = sortedImageFileArr(tempFiles as any[]);

    setFileUrls(sortedFiles);
  };

  // 과제 수정
  const handleSubmit = async (): Promise<void> => {
    // console.log(files, fileUrls);
    if (location.pathname.includes("hidden")) {
      // return alert("과제를 복구한 후에 수정해 주세요.");
      return alert(t(`Please make your edits after you restore the assignment`));
    }
    const result = handleModifyValidCheck(
      studentData,
      cardData?.repeatType === "count" ? cardData?.repeatCount : cardData?.repeatDate,
      cardData?.repeatType === "count" ? selectRepeatCount : dates,
    );

    let tempTags: any = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].tagSelected) {
        tempTags.push(i);
      }
    }
    if (!result) {
      // const confirm = window.confirm(
      //   "해당 날짜/횟수 박스에 이미 과제를 제출한 학생이 있습니다. 해당 학생들의 과제물 및 교사의 피드백, 쿠키 등이 완전히 삭제됩니다. 진행하시겠습니까?",
      // );
      const confirm = window.confirm(t(`There are students who have already submitted assignments in that...`));
      if (confirm) {
        if (blockSubmit) return;
        if (studentCheckWait) return;
        setBlockSubmit(true);
        setStudentCheckWait(true);
        let tempCodeList = [];
        for (let i in studentAll) {
          if (studentAll[i].checked) {
            tempCodeList.push(studentAll[i].code);
          }
        }
        let tempUrls: string[] = [];
        let tempFileTypes: string[] = [];
        let tempFileNames: string[] = [];

        for (let i = 0; i < fileUrls.length; i++) {
          let getUrl: string | boolean = "";
          if (fileUrls[i].file) {
            const formData = new FormData();
            formData.append("file", fileUrls[i].file as File);
            getUrl =
              fileUrls[i].fileType === "image" ? await postUploadImage(formData) : await postUploadFile(formData);
          } else {
            getUrl = fileUrls[i].fileUrl;
          }

          tempUrls.push(getUrl as string);
          tempFileTypes.push(fileUrls[i].fileType);
          tempFileNames.push(fileUrls[i].fileName);
        }

        const data = {
          projectId: params.id,
          tags: tempTags,
          title: projectNameInput,
          description: text,
          file: [...tempUrls],
          fileTypes: tempFileTypes,
          fileNames: tempFileNames,
          repeat: repeatCheck,
          // repeatType: repeatCheck ? (repeatProjectType === "횟수형" ? "count" : "date") : "",
          repeatType: repeatCheck ? (repeatProjectType === t(`By Number of times`) ? "count" : "date") : "",
          repeatCount:
            repeatProjectType === t(`By Number of times`) // "횟수형"
              ? selectRepeatCount
              : repeatProjectType === t(`By Date Selection`) // "날짜형"
              ? dates.length
              : 0,
          studentCodeList: tempCodeList,
          repeatDate: dates,
        };
        const postResult = await putModifyProject(data);

        setFileUrls([]);
        setPopup(false);
        setBlockSubmit(false);
        setStudentCheckWait(false);
        init();
      } else {
        return;
      }
    } else {
      if (blockSubmit) return;
      if (studentCheckWait) return;
      setBlockSubmit(true);
      setStudentCheckWait(true);
      let tempCodeList = [];
      for (let i in studentAll) {
        if (studentAll[i].checked) {
          tempCodeList.push(studentAll[i].code);
        }
      }
      let tempUrls: string[] = [];
      let tempFileTypes: string[] = [];
      let tempFileNames: string[] = [];

      for (let i = 0; i < fileUrls.length; i++) {
        let getUrl: string | boolean = "";
        if (fileUrls[i].file) {
          const formData = new FormData();
          formData.append("file", fileUrls[i].file as File);
          getUrl = fileUrls[i].fileType === "image" ? await postUploadImage(formData) : await postUploadFile(formData);
        } else {
          getUrl = fileUrls[i].fileUrl;
        }

        tempUrls.push(getUrl as string);
        tempFileTypes.push(fileUrls[i].fileType);
        tempFileNames.push(fileUrls[i].fileName);
      }

      const data = {
        projectId: params.id,
        title: projectNameInput,
        tags: tempTags,
        description: text,
        file: [...tempUrls],
        fileTypes: tempFileTypes,
        fileNames: tempFileNames,
        repeat: repeatCheck,
        // repeatType: repeatCheck ? (repeatProjectType === "횟수형" ? "count" : "date") : "",
        repeatType: repeatCheck ? (repeatProjectType === t(`By Number of times`) ? "count" : "date") : "",
        repeatCount:
          repeatProjectType === t(`By Number of times`) // "횟수형"
            ? selectRepeatCount
            : repeatProjectType === t(`By Date Selection`) // "날짜형"
            ? dates.length
            : 0,
        studentCodeList: tempCodeList,
        repeatDate: dates,
      };
      const postResult = await putModifyProject(data);

      setFileUrls([]);
      setPopup(false);
      setBlockSubmit(false);
      setStudentCheckWait(false);
      init();
    }
  };

  const handleConfirmPosBulk = async (): Promise<void> => {
    let cookie = 0;
    let tempScore = "";
    // if (bulkInfo.cookie !== 0 && bulkInfo.score !== "")
    //   return alert("과제 확인시 쿠키나 성적 중 한 가지만 선택 가능합니다.");
    if (bulkInfo.cookie !== 0) cookie = bulkInfo.cookie;
    if (bulkInfo.score !== "") tempScore = bulkInfo.score;

    let tempSelectedStudents = studentData.filter((el: any) => el.checked);
    if (tempSelectedStudents.length === 0) {
      // return alert("선택된 학생이 없습니다.");
      return alert(t(`No students are selected`));
    }

    const fetchArr = [];
    for (let i in tempSelectedStudents) {
      fetchArr.push(
        putConfirmProject({
          id: tempSelectedStudents[i]._id,
          repeat: false,
          confirmType: bulkInfo.cookie !== 0 ? "cookie" : "score",
          confirmScore: tempScore,
          confirmCookies: cookie,
          feedback: bulkInfo.feedback,
        }),
      );
    }

    const confirmResult: any[] = await Promise.all(fetchArr);

    for (let i = 0; i < confirmResult.length; i++) {
      if (confirmResult[i].constructor === Array) {
        let pushArr: any = [];
        confirmResult[i].map((pushItem: any, i: number) => {
          pushArr.push({
            token: tempSelectedStudents[i]?.studentInfo[0].deviceToken2,
            // to: "ExponentPushToken[AyHls_EO3cbqCORV1ffmCV]",
            notification: {
              title: `🎖${pushItem.title} ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
            },
            data: {
              code: tempSelectedStudents[i]?.studentInfo[0].code,
              type: "project",
            },
          });
        });
        postNotificationAll(pushArr);
      }

      // 과제확인시 푸시알림 추가
      let body = {
        to: tempSelectedStudents[i]?.studentInfo[0].deviceToken2,
        // title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        title: confirmType === "cookie" ? `${t(`You've earned cookies`)} 🍪` : `${t(`Check your assignment`)} 📝`,
        body:
          confirmType === "cookie"
            ? t(`You've earned cookies Check it out`) // "쿠키가 도착했어요! 확인해보세요!"
            : t(`You have a new assignment`), // "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: tempSelectedStudents[i]?.studentInfo[0].code,
          type: "project",
        },
      };

      postNotification(body);
    }

    context.handleStateChange("class", "");
    context.handleStateChange("easyConfirmPos", false);
    setConfirmBulk(false);
    setBulkInfo({
      cookie: 0,
      score: "",
      feedback: "",
    });

    init();
  };

  const handleChangeBulkInfo = (type: string, value: string | number) => {
    if (type == "cookie" && value !== 0 && value === bulkInfo.cookie) {
      setBulkInfo((prev: any) => {
        return {
          ...prev,
          cookie: 0,
        };
      });
    } else {
      setBulkInfo((prev: any) => {
        return {
          ...prev,
          [type]: value,
        };
      });
    }
  };

  const handleStudentChecked = (i: number) => {
    setStudentCheckWait(true);
    const temp = [...studentAll];
    temp[i].checked = !temp[i].checked;
    setStudentAll(temp);
    setStudentCheckWait(false);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    setStudentCheckWait(true);
    const temp = [...studentAll];
    for (let i in temp) {
      temp[i].checked = state;
    }

    setStudentAll(temp);
    setStudentCheckWait(false);
  };

  const handleProjectPopup = async (e: any, data: any, index: number): Promise<void> => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      // return alert(
      //   "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
      // );
      return alert(t(`You have exceeded the storage limit...`));
    }
    if (confirmBulk) return handleCheckPos(e, data);

    setTargetIdx(index);
    setProjectOfStudentData(data);
    setPopupStudentName(data.studentInfo[0].name);

    if (data.studentBody) {
      setStudentBodyFalse(true);
    } else {
      setStudentBodyFalse(false);
    }
    setSelectedStudentCode(data.code);
    setProjectOfStudent(data ? data : []);
    if (data.feedback !== "") setFeedback(data.feedback);
    if (data.state === "") {
      setConfirmType("cookie");
      setScore("");
      setFeedback("");
      setCookieSelected(1);
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "check") {
      setScore("");
      setFeedback("");
      setConfirmType("cookie");
      setCookieSelected(1);
      setRejectReason("");
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (
      (data.state === "complete" && data.score !== "") ||
      (data.state === "complete" && data.score === "" && data.cookie === 0)
    ) {
      setScore(data.score);
      setConfirmType("score");
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "complete" && data.cookie !== 0) {
      setCookieSelected(data.cookie);
      setConfirmType("cookie");
      setRejectReason(data.reject === "_" ? "" : data.reject);
    }
    if (data.state === "" && data.reject !== "") {
      setRejectReason(data.reject === "_" ? "" : data.reject);
      setConfirmType("reject");
    }
    if (hidden) {
      setHiddenProjectPopup(true);
    } else {
      setConfirmProjectPopup(true);
    }
  };

  const handleClickOutside = (e: any): void => {
    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) {
        setConfirmProjectPopup(false);
        setPopup(false);
        setConfirmType("");
        setFeedback("");
        setHiddenPopup(false);
        // setDates([]);
      }
    }
  };

  const handleTargetStudentCheck = (): void => {
    const tempAll = [...studentAll];
    for (let j in studentAll) {
      for (let i in studentData) {
        if (studentAll[j]._id === studentData[i]._id) tempAll[j].checked = true;
      }
    }

    setStudentAll(tempAll);
    if (hidden) {
      setHiddenPopup(true);
    } else {
      setPopup(true);
    }
  };

  const handleConfirmProject = async (): Promise<void> => {
    if (hidden) {
      // return alert("숨김상태에서는 과제의 열람만 가능합니다.");
      return alert(t(`While archived assignments can only be viewed`));
    }

    let cookie = 0;
    let tempScore = "";
    if (confirmType === "cookie" && cookieSelected !== 0) cookie = cookieSelected;
    if (confirmType === "score" && score !== "") tempScore = score;
    if (confirmType === "reject") {
      // const confirm = window.confirm(
      //   `반려하면 학생의 자료가 삭제되며, 복구가 되지 않습니다. 진행하시겠습니까?`,
      // );
      const confirm = window.confirm(t(`If you reject...`));
      if (!confirm) return;
      const result: any = await putRejectProject({
        id: studentData[targetIdx]._id,
        repeat: false,
        rejectReason: rejectReason === "" ? "_" : rejectReason,
      });

      let body = {
        to: studentData[targetIdx].studentInfo[0].deviceToken2,
        // title: "과제가 반려되었습니다. 📝",
        title: `${t(`Your assignment has been rejected`)} 📝`,
        // body: "다시 보내주세요.",
        body: t(`Please resubmit it`),
        data: {
          code: studentData[targetIdx].studentInfo[0].code,
          type: "project",
        },
      };

      postNotification(body);
      // alert("과제가 반려되었습니다.");
      alert(t(`Your assignment has been rejected`));
    } else {
      const result: any = await putConfirmProject({
        id: studentData[targetIdx]._id,
        repeat: false,
        confirmType,
        confirmScore: tempScore,
        confirmCookies: cookie,
        feedback: feedback,
      });

      if (result.constructor == Array) {
        let pushArr: any = [];
        result.map((pushItem: any, i: number) => {
          pushArr.push({
            token: studentData[targetIdx].studentInfo[0].deviceToken2,
            // to: "ExponentPushToken[AyHls_EO3cbqCORV1ffmCV]",
            notification: {
              title: `🎖${pushItem.title} ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
            },
            data: {
              code: studentData[targetIdx].studentInfo[0].code,
              type: "project",
            },
          });
        });
        postNotificationAll(pushArr);
      }

      let body = {
        to: studentData[targetIdx].studentInfo[0].deviceToken2,
        // title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        title: confirmType === "cookie" ? `${t(`You've earned cookies`)} 🍪` : `${t(`Check your assignment`)} 📝`,
        body:
          confirmType === "cookie"
            ? t(`You've earned cookies Check it out`) // "쿠키가 도착했어요! 확인해보세요!"
            : t(`You have a new assignment`), // "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: studentData[targetIdx].studentInfo[0].code,
          type: "project",
        },
      };

      postNotification(body);
    }

    context.handleStateChange("class", "");
    setConfirmProjectPopup(false);
    setConfirmType("");
    setFeedback("");
    init();
  };

  const handleConfirmTypeChange = (type: number): void => {
    // consoleLog(projectOfStudent);
    if (type === 1) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score === "") {
        setConfirmType("cookie");
        setCookieSelected(projectOfStudent.cookie ? projectOfStudent.cookie : 0);
      } else {
        setConfirmType("cookie");
        setCookieSelected(1);
      }
    } else if (type === 2) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score !== "") {
        setConfirmType("score");
        setScore(projectOfStudent.score ? projectOfStudent.score : "");
      } else {
        setConfirmType("score");
        setScore("");
      }
    } else if (type === 3) {
      if (projectOfStudent.state === "" && projectOfStudent.reject !== "") {
        setConfirmType("reject");
        // setRejectReason(projectOfStudent.reject === "_" ? "" : projectOfStudent.reject);
      } else {
        setConfirmType("reject");
        // setRejectReason("");
      }
    }
  };

  const handleAlbum = (data: any): void => {
    setAlbumData(data);
    // setAlbum(true);
    setAlbum((prev) => !prev);
    if (!data.isVideo) {
      setAlbumUrl(data.uri);
      setVideoUrl("");
    } else {
      setVideoUrl(videoUrl === "" ? data.uri : "");
      setAlbumUrl("");
    }
  };

  const handlePushNotification = async (deviceToken: string, name: string, code: string): Promise<void> => {
    // const confirm = window.confirm(`${name} 학생에게 푸쉬 알람을 보냅니다`);
    const confirm = window.confirm(`${name} ${t(`Send push notifications to students`)}`);
    if (confirm) {
      let body = {
        to: deviceToken,
        title: `${cardData.title}를 하세요!💌`,
        // body: "아직 안 한 과제가 있어요!",
        body: t(`You still have an assignment to complete`),
        data: {
          type: "project",
          code: code,
        },
      };
      await postNotification(body);
      // alert("푸쉬 알람을 보냈습니다.");
      alert(t(`You've sent a push notification`));
    } else {
      return;
    }
  };

  const handlePushNotificationAll = async (): Promise<void> => {
    const filteredStudentData = studentData.filter((data) => data.state === "");
    if (filteredStudentData.length === 0) {
      // return alert("과제를 완료하지 않은 학생이 없습니다.");
      return alert(t(`No students haven't completed the assignment`));
    }

    const confirm = window.confirm(
      // `${filteredStudentData.length} 명의 학생에게 푸쉬 알림을 보냅니다.`,
      `${filteredStudentData.length} ${t(`Send a push notification to the student`)}`,
    );

    if (confirm) {
      let pushArr = [];
      for (let i in filteredStudentData) {
        if (filteredStudentData[i].studentInfo[0].deviceToken2) {
          pushArr.push({
            token: filteredStudentData[i].studentInfo[0].deviceToken2,
            notification: {
              // title: `${cardData.title}를 하세요! 💌`,
              title: `${cardData.title}${t(`Make sure to finish your assignment`)} 💌`,
              // body: "아직 안 한 과제가 있어요!",
              body: t(`You still have an assignment to complete`),
            },
            data: {
              type: "project",
              code: filteredStudentData[i].studentInfo[0].code,
            },
          });
        }
      }

      postNotificationAll(pushArr);
      // alert("푸쉬 알림을 보냈습니다.");
      alert(t(`You've sent a push notification`));
    }
  };

  const handleDownloadAll = async (): Promise<void> => {
    // return alert("이 기능은 현재 점검중입니다. 자료를 하나씩 다운받아주세요.");
    return alert(t(`This feature is currently undergoing maintenance...`));
    if (resourceLoading) return;
    setResourceLoading(true);

    if (!cardData.repeat) {
      downloadZip();
    } else {
      downloadZipForRepeat();
    }
  };

  const convertURLtoFile = async (url: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
    const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };
    return new File([data], filename!, metadata);
  };

  const downloadZipForRepeat = async () => {
    const students = [...studentData];
    let zip: any = new JSZip();

    for (let i = 0; i < students.length; i++) {
      for (let k = 0; k < students[i].repeatData.length; k++) {
        if (students[i].repeatType === "count") {
          let folder = zip.folder(`${cardData?.title}/${students[i].studentInfo[0].name}/${k + 1} ${t(`Times`)}`);
          for (let h = 0; h < students[i].repeatData[k].fileUrls.length; h++) {
            const file = await convertURLtoFile(students[i].repeatData[k].fileUrls[h].uri);
            folder.file(file.name, file);
          }
        } else {
          let folder = zip.folder(
            `${cardData?.title}/${students[i].studentInfo[0].name}/${time1(students[i].repeatData[k].alertDate)}`,
          );
          for (let h = 0; h < students[i].repeatData[k].fileUrls.length; h++) {
            const file = await convertURLtoFile(students[i].repeatData[k].fileUrls[h].uri);
            folder.file(file.name, file);
          }
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content: any) => {
      saveAs(content, `${cardData?.title}.zip`);
    });

    setResourceLoading(false);
  };

  const downloadZip = async () => {
    let students = [...studentData];
    let zip = new JSZip();
    for (let i = 0; i < students.length; i++) {
      let folder: any = zip.folder(`${students[i].studentInfo[0].name}`);
      for (let k = 0; k < students[i].fileUrls.length; k++) {
        let binery: any = await convertURLtoFile(students[i].fileUrls[k].uri);
        folder.file(binery.name, binery);
      }
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${cardData?.title}.zip`);
    });
    setResourceLoading(false);
  };

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const replace = (content: any) => {
    const convertContent = content.replace(urlRegex, function (url: any) {
      return '<a style="color: blue" href="' + url + '" target="_blank">' + url + "</a>";
    });

    const htmlArr: any = [];
    convertContent.split("\n").forEach(function (text: any) {
      const textHtml = "<p>" + text + "</p>";
      htmlArr.push(textHtml);
    });

    return { __html: htmlArr.join("") };
  };

  const handleChangeFeedback = (text: string) => {
    setFeedback(text);
  };

  const getCount = (): number[] => {
    let temp = [];
    for (let i = 2; i <= 21; i++) {
      temp.push(i);
    }
    return temp;
  };

  const handleSelectDate = (day: any) => {
    const tempDates: any = [];
    let exist: boolean = false;
    for (let i in dates) {
      if (dates[i] === day.valueOf()) {
        exist = true;
      } else {
        tempDates.push(dates[i]);
      }
    }
    if (!exist) tempDates.push(day.valueOf());
    if (tempDates.length === 22) {
      // return alert("과제는 21개 이상 등록하실 수 없습니다.");
      return alert(t(`Registration of more than 21 assignments is not allowed`));
    }

    setDates(tempDates);
  };

  const handleDeleteImg = async (item: any, index: number) => {
    const removeFile = fileUrls.filter((el: IfileInfo, i: number) => el !== item);
    setFileUrls(removeFile);
  };

  const handleSelectTag = (tag: any, i: number) => {
    let tempTags = [...tags];
    const findTagIndex = tags.findIndex((el: any, index: number) => el === tag);
    tempTags[findTagIndex].tagSelected = !tags[findTagIndex].tagSelected;
    setTags(tempTags);
  };

  const handleCheckPos = (e: any, data: any) => {
    e.stopPropagation();
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You have exceeded the storage limit...`),
      );
    }

    let tempData = [...studentData];
    const findIdx = tempData.findIndex((el) => el === data);

    tempData[findIdx].checked = !tempData[findIdx].checked;
    setStudentData(tempData);
  };

  const handleCancelConfirmBulk = () => {
    context.handleStateChange("easyConfirmPos", false);

    let temp = [...studentData];
    for (let i in temp) {
      temp[i].checked = false;
    }

    setBulkInfo({
      cookie: 0,
      score: "",
      feedback: "",
    });
    setConfirmBulk(false);
    setStudentData(temp);
  };

  const onObserverAllCheck = () => {
    let allCheck = true;
    let checkCount = 0;
    for (let i in studentData) {
      if (studentData[i].checked) checkCount++;
    }

    if (checkCount === studentData.length) allCheck = true;
    else allCheck = false;

    return allCheck;
  };

  const handleStudentAllCheck = () => {
    let temp = [...studentData];

    for (let i in temp) {
      temp[i].checked = studentAllCheck ? false : true;
    }

    setStudentData(temp);
    setStudentAllCheck((prev: boolean) => !prev);
  };

  return (
    <div className="col-md-12 category_container">
      {album && (
        <PopupAlbum
          enable={album}
          albumData={albumData}
          setAlbumData={setAlbumData}
          fileData={projectOfStudent?.fileUrls}
          handleAlbum={handleAlbum}
          setAlbumUrl={setAlbumUrl}
          setVideoUrl={setVideoUrl}
          albumUrl={albumUrl}
          videoUrl={videoUrl}
        />
      )}

      <div className="display-f justify-between align-center mb-33 pl-10 pr-10" style={{ flexWrap: "wrap" }}>
        <div className="display-f align-center">
          <img
            onClick={() => history.goBack()}
            src={back}
            className="mr-20 cursor"
            style={{ marginLeft: -10, width: 45, height: "auto" }}
          />
          <p className="font-23 font-700">{cardData?.title}</p>
        </div>

        {!confirmBulk && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button onClick={handleTargetStudentCheck} className="project_control_btn mr-28">
              {/* {!hidden ? "과제 정보 수정" : "과제 정보"} */}
              {!hidden ? t(`Modify Assignment info`) : t(`Task infomation`)}
            </button>

            {/* 25.01.13 모든자료 다운받기 개발이슈로 인하여 일단 주석처리 및 비활성화 */}
            {/* {!hidden && (
              <button
                onClick={handleDownloadAll}
                className="project_control_btn mr-28"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                {
                  // 모든 자료 다운받기
                  !resourceLoading && t(`Download all data`)
                }
                {resourceLoading && <div className="spinner3"></div>}
              </button>
            )} */}

            {!hidden && !cardData?.repeat && (
              <button
                onClick={() => {
                  setConfirmBulk(true);
                  context.handleStateChange("easyConfirmPos", true);
                }}
                className="project_control_btn mr-28 border-red text-red font-500 bg-white"
              >
                {/* 일괄 검사 */}
                {t(`Mark All`)}
              </button>
            )}
          </div>
        )}

        {!confirmBulk && (
          <div className="display-f">
            <OutsideClickHandler
              onOutsideClick={() => {
                setFilterPopup(false);
              }}
            >
              <div style={{ position: "relative" }}>
                <button
                  style={{ marginRight: 10 }}
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>

                {filterPopup && (
                  <div className="position-a filter_contents">
                    {filter.map((data: string, index: number) => (
                      <div onClick={() => handleFilterSelect(data)} className="filter_inner cursor" key={index}>
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            {!hidden && (
              <button onClick={handlePushNotificationAll} className="push_all_btn font-500">
                {/* "전체 Push 보내기" */}
                {t(`Send All Push Notifications`)}
              </button>
            )}
          </div>
        )}

        {hidden && <div style={{ width: 152, height: 36 }} />}
      </div>

      {/* 과제 일괄 확인의 경우 */}
      {confirmBulk && (
        <div style={{ marginTop: -30 }}>
          <div className="display-f flex-1 mb-10 align-center">
            <div className="flex-1 display-f align-center">
              <div className="display-f align-center position-r">
                <div onClick={handleStudentAllCheck} className="check cursor mr-5">
                  {onObserverAllCheck() && (
                    <span className="check__inside position-a bg-checked" style={{ left: 5, top: 5 }} />
                  )}
                </div>
              </div>
              <p onClick={handleStudentAllCheck} className="white cursor">
                {/* 전체 학생 선택 */}
                {t(`Select All Student`)}
              </p>
            </div>
            <div className="flex-2">
              <div className="display-f align-center justify-center">
                <>
                  <button
                    onClick={() => handleChangeBulkInfo("cookie", 1)}
                    className={`${
                      bulkInfo.cookie !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {bulkInfo.cookie === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => handleChangeBulkInfo("cookie", 2)}
                    className={`${
                      bulkInfo.cookie !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {bulkInfo.cookie === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => handleChangeBulkInfo("cookie", 3)}
                    className={`${
                      bulkInfo.cookie !== 3 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {bulkInfo.cookie === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>

                  <div className="mr-20 display-f align-center">
                    <h2>
                      {/* 또는 */}
                      {t(`Or`)}
                    </h2>
                    <input
                      // value={cookieSelected}
                      // onChange={(e: any) => {
                      //   const numParse = parseInt(e.nativeEvent.data);
                      //   if (e.nativeEvent.data === null && e.target.value === "") {
                      //     setCookieSelected(0);
                      //   }
                      //   if (e.nativeEvent.data === null && e.target.value !== "") {
                      //     setCookieSelected(parseInt(e.target.value));
                      //   }
                      //   if (!isNaN(numParse)) {
                      //     setCookieSelected(parseInt(e.target.value));
                      //   }
                      // }}

                      onChange={(e: any) => handleChangeBulkInfo("score", e.target.value)}
                      value={bulkInfo.score}
                      // placeholder="성적 입력"
                      placeholder={t(`Enter grade`)}
                      className="add_cookie_input ml-10"
                      style={{ width: 130 }}
                      // style={{ fontSize: 20 }}
                    />
                  </div>
                </>
              </div>
            </div>
            <div className="flex-1 display-f justify-end">
              <button
                onClick={handleConfirmPosBulk}
                style={{ width: 80, height: 30, backgroundColor: "#fff" }}
                className="display-ib mr-10 select_btn text-red border-red font-500"
              >
                {/* 저장 */}
                {t(`Save`)}
              </button>
              <button
                className="select_btn display-ib"
                style={{ width: 80, height: 30 }}
                onClick={handleCancelConfirmBulk}
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
          <div className="mb-20">
            <input
              onChange={(e: any) => handleChangeBulkInfo("feedback", e.target.value)}
              value={bulkInfo.feedback}
              className="easy_check_cookie text-center"
              // 내용을 간단히 입력해 주세요
              placeholder={t(`Please briefly note`)}
            />
          </div>
        </div>
      )}

      <div className="display-f flex-wrap">
        {studentData?.map((data: any, index: number): any => {
          // 미확인 / 반려의 경우
          if ((data.state === "" || data.state === "reject") && !data.repeat) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  onClick={(e: any) => handleProjectPopup(e, data, index)}
                  // onClick={(e: any) => {
                  //   if (hidden) {
                  //     handleProjectPopup(e, data, index);
                  //   }
                  // }}
                  className={`card project_inner_card background-yellow direction-column position-r cursor`}
                >
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, data)}
                      className="position-a check cursor"
                      style={{ left: 4 }}
                    >
                      {data.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div className="display-f justify-between mb-12">
                    <p className="font-15 color-dark font-700">{data.studentInfo[0].name}</p>&nbsp;
                    <p className="color-yellow font-500">
                      {/* 미제출 */}
                      {t(`Not Submitted`)}
                    </p>
                  </div>
                  {!hidden && !confirmBulk && (
                    <>
                      <button
                        onClick={(e: any) => {
                          handleProjectPopup(e, data, index);
                        }}
                        className={`card_check_btn font-10 ${
                          data.submissionTime && !data.tempSubmissionTime ? "" : "mb-8"
                        }`}
                      >
                        {/* 낸것으로 하기 */}
                        {t(`Marked as submitted`)}
                      </button>
                      <button
                        onClick={(e) => {
                          if (confirmBulk) return handleCheckPos(e, data);
                          if (hidden) {
                            // return alert("숨김상태에서는 푸시알림을 보내실 수 없습니다.");
                            return alert(t(`You can't send push notifications while....`));
                          }
                          handlePushNotification(
                            data.studentInfo[0].deviceToken2,
                            data.studentInfo[0].name,
                            data.studentInfo[0].code,
                          );
                        }}
                        className="card_check_btn font-10 background-red-btn white"
                      >
                        Push
                      </button>
                    </>
                  )}

                  <p className="font-12 mt-2">
                    {data.submissionTime && !data.tempSubmissionTime && timeFormat11(data.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }

          if (data.state === "check" && !data.repeat) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  onClick={(e: any) => handleProjectPopup(e, data, index)}
                  className="card project_inner_card background-red direction-column check_card"
                >
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, data)}
                      className="position-a check cursor"
                      // style={{ left: 4 }}
                    >
                      {data.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div className="display-f justify-between">
                    <p className="font-15 color-dark font-700">{data.studentInfo[0].name}</p>&nbsp;
                    <p className="color-red font-500">
                      {/* 확인전 */}
                      {t(`Awaiting Marking`)}
                    </p>
                  </div>
                  {!hidden && !confirmBulk && (
                    <button className="card_check_btn font-10">
                      {/* 확인 */}
                      {t(`Confirm`)}
                    </button>
                  )}
                  <p className="font-12">
                    {data.submissionTime && !data.tempSubmissionTime && timeFormat11(data.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }

          if (data.state === "complete" && data.score !== "" && !data.repeat) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  onClick={(e: any) => handleProjectPopup(e, data, index)}
                  className="card project_inner_card background-gray direction-column"
                >
                  {confirmBulk && (
                    <div onClick={(e: any) => handleCheckPos(e, data)} className="position-a check cursor">
                      {data.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div className="display-f studentOfProject_card" style={{ paddingLeft: 28 }}>
                    <p className="font-15 color-dark font-700">{data.studentInfo[0].name}</p>
                  </div>
                  <p className="color-red font-30 font-700">{data.score}</p>
                  <p className="font-12">
                    {data.submissionTime && !data.tempSubmissionTime && timeFormat11(data.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }
          if (data.state === "complete" && !data.repeat) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  // onClick={() => setConfirmProjectPopup(true)}
                  onClick={(e: any) => handleProjectPopup(e, data, index)}
                  className="card project_inner_card background-gray direction-column"
                >
                  {confirmBulk && (
                    <div
                      onClick={(e: any) => handleCheckPos(e, data)}
                      className="position-a check cursor"
                      // style={{ left: 4 }}
                    >
                      {data.checked && <span className="check__inside position-a bg-checked" />}
                    </div>
                  )}
                  <div className="display-f studentOfProject_card" style={{ paddingLeft: 28 }}>
                    <p className="font-15 color-dark font-700">{data.studentInfo[0].name}</p>
                  </div>
                  {data.cookie === 1 && <img className="cookie" src={cookie1} alt="cookie1" />}
                  {data.cookie === 2 && <img className="cookie" src={cookie2} alt="cookie2" />}
                  {data.cookie === 3 && <img className="cookie" src={cookie3} alt="cookie3" />}
                  <p className="font-12">
                    {data.submissionTime && !data.tempSubmissionTime && timeFormat11(data.submissionTime)}
                  </p>
                </div>
              </div>
            );
          }

          // 반복과제일 경우
          if (data.repeat) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <Link
                  to={
                    location.pathname.includes("hidden")
                      ? `/page/hiddens/project/${params.classId}/${params.id}/${data._id}`
                      : `/page/project/${params.classId}/${params.id}/${data._id}`
                  }
                >
                  <div
                    className={`card2 project_inner_card2 direction-column cursor border-${
                      data.borderState
                    } background-${data.state === "check" ? "red" : data.state === "complete" ? "gray" : "yellow"}`}
                  >
                    <p className="font-15 color-dark font-700">{data.studentInfo[0].name}</p>
                    {hidden && <div style={{ width: 93, height: 25 }}></div>}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "3px 0",
                        marginTop: hidden ? "-5px" : "3px",
                      }}
                    >
                      <p style={{ fontSize: 22, fontWeight: "bold" }}>
                        {data.completeCount} / {cardData.repeatCount && cardData.repeatCount}
                        {cardData.repeatDate && cardData.repeatDate.length}
                      </p>
                    </div>
                    {!hidden && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (confirmBulk) return handleCheckPos(e, data);
                          if (hidden) {
                            // return alert("숨김상태에서는 푸시알림을 보내실 수 없습니다.");
                            return alert(t(`You can't send push notifications while...`));
                          } else {
                            handlePushNotification(
                              data?.studentInfo[0]?.deviceToken2,
                              data.studentInfo[0].name,
                              data?.studentInfo[0].code,
                            );
                          }
                        }}
                        className="card_check_btn_inactive font-10 background-red-btn white"
                      >
                        Push
                      </button>
                    )}
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>

      {/* hidden project popup */}
      {hiddenProjectPopup && (
        <HiddenProjectView
          rejectReason={rejectReason}
          score={score}
          feedback={feedback}
          cookieSelected={cookieSelected}
          confirmType={confirmType}
          handleAlbum={handleAlbum}
          handleClickOutside={() => setHiddenProjectPopup(false)}
          projectOfStudentData={projectOfStudentData}
        />
      )}

      {/* confirm project popup */}
      {confirmProjectPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
          <div ref={popupRef} className="projectOfStudentPopup scroll color-light-black">
            <p className="font-20 color-light-black mb-10 text-center">{popupStudentName}</p>

            <div>
              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {(aFile.fileType === "image" || aFile.fileType === "video" || !aFile.fileType) && (
                    <div
                      key={index}
                      style={{
                        width: "49%",
                        verticalAlign: "top",
                        display: "inline-block",
                        marginRight: index % 2 === 0 ? 10 : 0,
                        marginBottom: 10,
                      }}
                    >
                      {!aFile.isVideo && (
                        <img
                          className="cursor"
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          style={{ width: "100%", height: "auto", verticalAlign: "top" }}
                        />
                      )}
                      {aFile.isVideo && (
                        <video
                          controls
                          style={{ verticalAlign: "top" }}
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      )}
                      {/* <p className="txt_line">{aFile.fileName}</p> */}
                      <p
                        onClick={() => window.open(aFile.uri)}
                        className="download_text cursor"
                        style={{ textAlign: "center" }}
                      >
                        {/* 다운받기 */}
                        {t(`Download`)}
                      </p>
                    </div>
                  )}
                </>
              ))}

              {projectOfStudent?.fileUrls?.filter(
                (el: any, i: number) => el.fileType !== "image" && el.fileType !== "video" && el.fileType,
              ).length !== 0 && (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ fontWeight: 500, marginBottom: 10 }}>
                    {/* 첨부파일 */}
                    {t(`Attachments`)}
                    <span style={{ fontSize: 12 }}>
                      {/* (아래 파일명을 클릭하면 다운로드됩니다.) */}
                      {t(`Click the filename below to download`)}
                    </span>
                  </p>
                </div>
              )}

              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {aFile.fileType !== "image" && aFile.fileType !== "video" && aFile.fileType && (
                    <div onClick={() => window.open(aFile.uri)} key={index} className="file_ex_container cursor">
                      <img src={file_icon} style={{ width: 25, height: "auto", marginRight: 5 }} />
                      <p>{aFile.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>

            {/* <div className="display-f align-center flex-wrap">
              {projectOfStudent?.fileUrls?.map((data: any, index: number) => (
                <div
                  key={index}
                  className="display-f download_item mb-10 align-center"
                  style={{
                    width: "49%",
                    marginRight: index % 2 === 1 ? 0 : 5,
                    marginLeft: index % 2 === 0 ? 0 : 5,
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {data.isVideo ? (
                      <>
                        <video
                          controls
                          onClick={() => handleAlbum(data)}
                          src={data.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      </>
                    ) : (
                      <div onClick={() => handleAlbum(data)} className="mb-10 cursor">
                        <img src={data.uri} alt="studentImg" className="img-response" />
                      </div>
                    )}
                  </div>

                  <p onClick={() => window.open(data.uri)} className="download_text cursor">
                    다운받기
                  </p>
                </div>
              ))}
            </div> */}

            {studentBodyFalse && (
              <div className="project_list mb-7" style={{ borderRadius: 10 }}>
                <p>
                  <div className="cursor" dangerouslySetInnerHTML={replace(projectOfStudent.studentBody)}></div>
                </p>
              </div>
            )}

            {!projectOfStudent.tempSubmissionTime && projectOfStudent.submissionTime && (
              <p className="mb-7 font-bold">
                {timeFormat11(projectOfStudent.submissionTime)}
                {/* 제출 */}
                {t(`Submit`)}
              </p>
            )}

            {confirmType !== "reject" && (
              <div className="display-f  align-center mb-20">
                <p className="font-15 font-500 mr-33">
                  {/* 피드백 */}
                  {t(`Feedback`)}
                </p>

                <textarea
                  value={feedback}
                  onChange={(e: any) => handleChangeFeedback(e.target.value)}
                  className="feedback__text"
                  name="feedback"
                  readOnly={confirmType === "reject" && true}
                  cols={20}
                ></textarea>
                {/* <div className="feedback__text">
                  <ReactQuill
                    value={feedback}
                    onChange={(e: any) => {
                      const s = e.replace(/<(\/a|a)([^>]*)>/gi, "");
                      setFeedback(parseLinkTextToHTML(s));

                      console.log("지운태그 -->", s);
                      console.log(parseLinkTextToHTML(s));
                    }}
                    // className="feedback__text"
                    modules={quillStyle2}
                  />
                </div> */}
              </div>
            )}
            <div className="display-f justify-between align-center mb-28">
              <button
                onClick={() => handleConfirmTypeChange(1)}
                className={`focus project__sign__btn light-black ${confirmType === "cookie" && "confirm_selected"}`}
              >
                {/* 쿠키 */}
                {t(`Cookie`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(2)}
                className={`focus project__sign__btn light-black ${confirmType === "score" && "confirm_selected"}`}
              >
                {/* 성적 */}
                {t(`Grade`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(3)}
                className={`focus project__sign__btn light-black project_reject_btn ${
                  confirmType === "reject" && "confirm_reject_selected"
                }`}
              >
                {/* 반려 */}
                {t(`Reject`)}
              </button>
            </div>
            <div className="mb-40">
              {confirmType === "cookie" && (
                <div className="display-f align-center">
                  <button
                    onClick={() => setCookieSelected(1)}
                    className={`${
                      cookieSelected !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(2)}
                    className={`${
                      cookieSelected !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => setCookieSelected(3)}
                    className={`${cookieSelected !== 3 && "cookie_border_none"} cookie_circle cursor display-f-center`}
                  >
                    {cookieSelected === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>
                </div>
              )}
              {confirmType === "score" && (
                <div className="display-f align-center">
                  <p className="mr-23 font-500 font-15">
                    {/* 성적 입력 */}
                    {t(`Enter grade`)}
                  </p>
                  <input
                    value={score}
                    maxLength={4}
                    onChange={(e: any) => setScore(e.target.value)}
                    className="score_input"
                    type="text"
                  />
                </div>
              )}
              {confirmType === "reject" && (
                <>
                  <div className="display-f align-center justify-between">
                    <p className="font-500 font-15">
                      {/* 반려 사유 */}
                      {t(`Rejection Reason`)}
                    </p>
                    <textarea
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      className="reject__reason__text"
                      name="rejectReason"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="display-f-center">
              <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
                {/* 확인 */}
                {t(`Confirm`)}
              </button>
              <button
                onClick={() => {
                  setConfirmProjectPopup(false);
                  setConfirmType("");
                  setScore("");
                  setCookieSelected(0);
                  setRejectReason("");
                  setFeedback("");
                  // setDates([]);
                }}
                className="popup__bottom__btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 과제정보 보기, 과제수정 팝업 */}
      {/* popup */}
      {popup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)} />
          <div ref={popupRef} className="project_add_popup scroll">
            {/* 과제 수정 */}
            <p className="font-23 mb-26 text-center">{t(`Edit Assignments`)}</p>
            {/* 과제 이름 */}
            <p className="color-light-black mb-8 font-15">{t(`Assignment title`)}(*)</p>
            <div className="display-f align-center mb-22">
              <input
                value={projectNameInput}
                onChange={(e: any) => setProjectNameInput(e.target.value)}
                className="popup_input mr-18 pl-12 pr-12"
                type="text"
                maxLength={31}
              />
            </div>

            <p className="color-light-black mb-8 font-15">{t(`Tag`)}</p>
            {tags?.filter((item: any, i: number) => item.tagName).length === 0 && (
              <p style={{ fontSize: 11, color: "#979797" }}>
                {/* 아직 등록된 분류 기준이 없습니다. 분류 기준을 추가해 주세요! */}
                {t(`There are no tags yet please add one`)}
              </p>
            )}

            <div style={{ width: "65%", marginBottom: 20 }}>
              {tags
                .filter((item: any, i: number) => item.tagName)
                .map((tag: any, index: number) => (
                  <div
                    key={index}
                    className={`modal_in_tag ${index % 5 !== 4 && "mr-10"} ${index > 4 && "mt-5"}`}
                    style={{ display: "inline-block" }}
                  >
                    <button
                      onClick={() => handleSelectTag(tag, index)}
                      style={{ width: "100%" }}
                      className={`modal_tag_btn ${tag.tagSelected && "tag_btn_selected"}`}
                    >
                      {tag.tagName}
                    </button>
                  </div>
                ))}
            </div>

            {/* 반복과제 */}
            <div style={{ marginBottom: 20 }} className="position-r">
              <div className="display-f">
                <input
                  onChange={() => {
                    if (!repeatCheck) {
                      return;
                    }
                  }}
                  checked={repeatCheck}
                  type="checkbox"
                  id={JSON.stringify("repeat")}
                  className="display-n"
                />
                <div>
                  <label className="target_label2 cursor" htmlFor={JSON.stringify("repeat")} style={{ marginRight: 5 }}>
                    {repeatCheck && <span className="studentList_check"></span>}
                  </label>
                </div>
                <p
                  onClick={() => {
                    if (!repeatCheck) {
                      // setRepeatProjectType("횟수형");
                      return;
                    }
                  }}
                  className="color-light-black font-15 mb-8 cursor"
                >
                  {/* 반복과제 설정 */}
                  {t(`Repeat`)}{" "}
                  <span className="font-12" style={{ color: "#de4949" }}>
                    {/* (과제 최초 생성 후, 반복/일반 과제로의 변경은 불가합니다.) */}
                    {"   "}
                    {t(`Once an assignment is created...`)}
                  </span>
                </p>
              </div>

              <>
                <button
                  disabled={true}
                  onClick={() => {
                    setRepeatCheck(true);
                    // setRepeatProjectType("횟수형");
                    setRepeatProjectType(t(`By Number of times`));
                  }}
                  className="repeat-type-btn"
                  style={{
                    marginLeft: 25,
                    marginRight: 10,
                    // backgroundColor: repeatProjectType === "횟수형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "횟수형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Number of times`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Number of times`) ? "#000" : "#979797",
                  }}
                >
                  {/* 횟수형 */}
                  {t(`By Number of times`)}
                </button>
                <button
                  disabled={true}
                  onClick={() => {
                    setRepeatCheck(true);
                    // setRepeatProjectType("날짜형");
                    setRepeatProjectType(t(`By Date Selection`));
                  }}
                  className="repeat-type-btn"
                  style={{
                    // backgroundColor: repeatProjectType === "날짜형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "날짜형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Date Selection`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Date Selection`) ? "#000" : "#979797",
                  }}
                >
                  {/* 날짜형 */}
                  {t(`By Date Selection`)}
                </button>

                {/* 횟수형이면 */}
                {repeatProjectType === t(`By Number of times`) && (
                  <>
                    <div style={{ marginLeft: 25, alignItems: "center", marginTop: 10 }} className="display-f">
                      <div
                        onClick={() => setRepeatSelectOpenC((prev: any) => !prev)}
                        className="repeat-selectbar cursor"
                      >
                        <p>{selectRepeatCount}</p>
                        <img src={down_b} style={{ width: 18, height: "auto" }} />
                      </div>
                      <p>
                        {/* 회 */}
                        {t(`Times`)}
                      </p>
                    </div>

                    {repeatSelectOpenC && (
                      <div className="repeat-selectbar-open">
                        {getCount().map((numberItem: number, i: number) => (
                          <div
                            key={i}
                            onClick={() => {
                              setSelectRepeatCount(numberItem);
                              setRepeatSelectOpenC(false);
                            }}
                            className="repeat-select-item-n cursor"
                            style={{
                              fontWeight: numberItem === selectRepeatCount ? "bold" : "normal",
                            }}
                          >
                            {numberItem}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {/* 날짜형 */}
                {repeatProjectType === t(`By Date Selection`) && (
                  <>
                    <div style={{ marginTop: 10 }}>
                      <CalendarC selectDates={dates} setSelectDates={setDates} handleSelectDate={handleSelectDate} />
                    </div>
                  </>
                )}
              </>
            </div>
            {/* 반복과제 */}
            <p className="color-light-black font-15 mb-8">{t(`Instructions`)}(*)</p>
            <ReactQuill
              value={text}
              onChange={(e) => setText(e)}
              className="project-quill"
              modules={quillStyle}
              formats={formatStyle}
            />
            <div
            // className="img-container"
            >
              {fileUrls.map((data: IfileInfo, index: number) => (
                <>
                  {data.fileType === "image" && (
                    <div
                      className="img-add-wrapper"
                      key={index}
                      style={{ marginRight: index % 3 === 2 ? 0 : 10, display: "inline-block" }}
                    >
                      <img
                        onClick={() => handleDeleteImg(data, index)}
                        src={close}
                        style={{ width: 17, height: 17 }}
                        className="img-delete"
                      />

                      <img src={data.fileUrl} className="img-add mb-10" alt="file" />
                      {/* <p className="txt_line" style={{ fontSize: 13 }}>
                        {data.fileName}
                      </p> */}
                    </div>
                  )}

                  {data.fileType !== "image" && (
                    <div className="mt-10 display-f align-center">
                      <img
                        src={close}
                        onClick={() => handleDeleteImg(data, index)}
                        style={{ width: 10, height: 10 }}
                        className="cursor mr-5"
                      />
                      <img src={file_icon2} style={{ width: 30, height: "auto", marginRight: 3 }} alt="file" />
                      <p style={{ fontSize: 13 }}>{data.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="popup__additional__project mb-30">
              {!hidden && (
                <>
                  <input
                    ref={fileRef}
                    type="file"
                    className="display-n"
                    name="file"
                    multiple
                    onChange={(e: any) => handleFile(e)}
                  />
                  <button
                    onClick={() => {
                      fileRef.current !== null && fileRef.current.click();
                    }}
                    className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                  >
                    {t(`Attach`)}
                  </button>
                </>
              )}

              {fileUrls && !hidden && (
                <button
                  onClick={() => {
                    setFileUrls([]);
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 전체 삭제 */}
                  {t(`Delete All files`)}
                </button>
              )}

              <button onClick={() => setStudentsList(true)} className="focus target__student__btn font-13 font-500">
                {/* 대상 학생 */}
                {`${t(`Select Recipient`)} (${studentAll.filter((item: IStudent) => item.checked).length})`}
              </button>

              {studentsList === true && (
                <div className="studentsList__popup">
                  <div className="studentsList__contents">
                    <div className="overflow-h scroll mb-30">
                      <p className="font-23 text-center mb-20 pt-20">
                        {/* 대상학생 목록 */}
                        {t(`List of recipients`)}
                      </p>
                      {studentAll
                        .sort((a: any, b: any) => a.num - b.num)
                        .map((data: IStudent, index: number) => {
                          return (
                            <div key={index} className="managing__options__contents position-r">
                              <p className="mt-15 mb-15 font-15">{data.name}</p>
                              <input
                                onChange={() => handleStudentChecked(index)}
                                type="checkbox"
                                id={JSON.stringify(index)}
                                className="display-n"
                              />
                              <div>
                                <label className="target_label cursor" htmlFor={JSON.stringify(index)}>
                                  {data.checked && <span className={`studentList_check`}></span>}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      <div>
                        <div className="text-center mt-20 mb-20 pb-20">
                          <button
                            onClick={() => handleStudentsAllCheck(true)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 전체 선택 */}
                            {t(`Select All`)}
                          </button>
                          <button
                            onClick={() => handleStudentsAllCheck(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두 해제 */}
                            {t(`Deselect All`)}
                          </button>
                          <button onClick={() => setStudentsList(false)} className="focus popup__bottom__btn mr-14">
                            {/* 확인 */}
                            {t(`Confirm`)}
                          </button>
                          <button onClick={() => setStudentsList(false)} className="focus popup__bottom__btn">
                            {/* 취소 */}
                            {t(`Cancel`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!hidden && (
              <div className="text-center mb-20">
                <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
                <button onClick={() => setPopup(false)} className="focus popup__bottom__btn">
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {hiddenPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)} />
          <div ref={popupRef} className="project_add_popup scroll">
            <p className="font-23 mb-26 text-center">
              {/* 과제 정보 */}
              {t(`Assignment information`)}
            </p>
            <p className="color-light-black mb-8 font-15">
              {/* 과제 이름 */}
              {t(`Assignment title`)}(*)
            </p>
            <div className="display-f align-center mb-22">
              <div className="font-500">{projectNameInput}</div>
            </div>

            <p className="color-light-black mb-8 font-15"> {t(`Tag`)}</p>
            {tags?.filter((item: any, i: number) => item.tagName).length === 0 && (
              <p style={{ fontSize: 11, color: "#979797" }}>
                {/* 아직 등록된 분류 기준이 없습니다. 분류 기준을 추가해 주세요! */}
                {t(`There are no tags yet please add one`)}
              </p>
            )}

            <div style={{ width: "65%", marginBottom: 20 }}>
              {tags
                .filter((item: any, i: number) => item.tagName)
                .map((tag: any, index: number) => (
                  <div
                    key={index}
                    className={`modal_in_tag ${index % 5 !== 4 && "mr-10"} ${index > 4 && "mt-5"}`}
                    style={{ display: "inline-block" }}
                  >
                    <button
                      style={{ width: "100%" }}
                      className={`modal_tag_btn ${tag.tagSelected && "tag_btn_selected"}`}
                    >
                      {tag.tagName}
                    </button>
                  </div>
                ))}
            </div>

            {/* 반복과제 */}
            <div style={{ marginBottom: 20 }} className="position-r">
              <div className="display-f">
                <input checked={repeatCheck} type="checkbox" id={JSON.stringify("repeat")} className="display-n" />
                <div>
                  <label className="target_label2 cursor" htmlFor={JSON.stringify("repeat")} style={{ marginRight: 5 }}>
                    {repeatCheck && <span className="studentList_check"></span>}
                  </label>
                </div>
                <p className="color-light-black font-15 mb-8 cursor">
                  {t(`Repeat`)}{" "}
                  <span className="font-12" style={{ color: "#de4949" }}>
                    {/* (과제 최초 생성 후, 반복/일반 과제로의 변경은 불가합니다.) */}
                    {"   "}
                    {t(`Once an assignment is created...`)}
                  </span>
                </p>
              </div>

              <>
                <button
                  disabled={true}
                  className="repeat-type-btn"
                  style={{
                    marginLeft: 25,
                    marginRight: 10,
                    // backgroundColor: repeatProjectType === "횟수형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "횟수형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Number of times`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Number of times`) ? "#000" : "#979797",
                  }}
                >
                  {/* 횟수형 */}
                  {t(`By Number of times`)}
                </button>
                <button
                  disabled={true}
                  className="repeat-type-btn"
                  style={{
                    // backgroundColor: repeatProjectType === "날짜형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "날짜형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Date Selection`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Date Selection`) ? "#000" : "#979797",
                  }}
                >
                  {/* 날짜형 */}
                  {t(`By Date Selection`)}
                </button>

                {/* 횟수형 이라면 */}
                {repeatProjectType === t(`By Number of times`) && (
                  <>
                    <div style={{ marginLeft: 25, alignItems: "center", marginTop: 10 }} className="display-f">
                      <div className="repeat-selectbar cursor">
                        <p>{selectRepeatCount}</p>
                        <img src={down_b} style={{ width: 18, height: "auto" }} />
                      </div>
                      <p>회</p>
                    </div>

                    {repeatSelectOpenC && (
                      <div className="repeat-selectbar-open">
                        {getCount().map((numberItem: number, i: number) => (
                          <div
                            key={i}
                            className="repeat-select-item-n cursor"
                            style={{
                              fontWeight: numberItem === selectRepeatCount ? "bold" : "normal",
                            }}
                          >
                            {numberItem}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {/* 날짜형 */}
                {repeatProjectType === t(`By Date Selection`) && (
                  <>
                    <div style={{ marginTop: 10 }}>
                      <CalendarC selectDates={dates} setSelectDates={() => {}} handleSelectDate={() => {}} />
                    </div>
                  </>
                )}
              </>
            </div>
            {/* 반복과제 */}
            <p className="color-light-black font-15 mb-8">
              {/* 과제 설명 */}
              {t(`Instructions`)}(*)
            </p>
            <div
              style={{
                padding: 10,
                minHeight: 150,
                overflow: "auto",
                border: "1px solid #ececec",
                borderRadius: 4,
              }}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
            <div>
              {fileUrls.map((data: IfileInfo, index: number) => (
                <>
                  {data.fileType === "image" && (
                    <div
                      className="img-add-wrapper"
                      key={index}
                      style={{ marginRight: index % 3 === 2 ? 0 : 10, display: "inline-block" }}
                    >
                      <img
                        onClick={() => handleDeleteImg(data, index)}
                        src={close}
                        style={{ width: 17, height: 17 }}
                        className="img-delete"
                      />

                      <img src={data.fileUrl} className="img-add mb-10" alt="file" />
                      {/* <p className="txt_line" style={{ fontSize: 13 }}>
                        {data.fileName}
                      </p> */}
                    </div>
                  )}

                  {data.fileType !== "image" && (
                    <div className="mt-10 display-f align-center">
                      <img
                        src={close}
                        onClick={() => handleDeleteImg(data, index)}
                        style={{ width: 10, height: 10 }}
                        className="cursor mr-5"
                      />
                      <img src={file_icon} style={{ width: 30, height: "auto", marginRight: 3 }} alt="file" />
                      <p style={{ fontSize: 13 }}>{data.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="popup__additional__project mb-30 mt-30">
              <p className="color-light-black font-15 mb-8">
                {/* 대상 학생 */}
                {t(`Select Recipient`)}
              </p>

              {studentAll
                .sort((a: any, b: any) => a.num - b.num)
                .map((data: IStudent, index: number) => {
                  return (
                    <div key={index} className="managing__options__contents position-r">
                      <p className="mt-15 mb-15 font-15">{data.name}</p>
                      <input
                        // onChange={() => handleStudentChecked(index)}
                        type="checkbox"
                        id={JSON.stringify(index)}
                        className="display-n"
                      />
                      <div>
                        <label className="target_label cursor" htmlFor={JSON.stringify(index)}>
                          {data.checked && <span className={`studentList_check`}></span>}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            {!hidden && (
              <div className="text-center mb-20">
                <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
                <button onClick={() => setPopup(false)} className="focus popup__bottom__btn">
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Project;
